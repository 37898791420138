/* stylelint-disable */
@import '~org/fonts';

@font-face {
    font-family: 'NotoSansTC';
    src: url('../fonts/noto-sans-tc-v26-chinese-traditional-700.woff2')
        format('woff2');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'NotoSansTC';
    src: url('../fonts/noto-sans-tc-v26-chinese-traditional-500.woff2')
        format('woff2');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'NotoSansTC';
    src: url('../fonts/noto-sans-tc-v26-chinese-traditional-regular.woff2')
        format('woff2');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'NotoSansTC';
    src: url('../fonts/noto-sans-tc-v26-chinese-traditional-900.woff2')
        format('woff2');
    font-style: normal;
    font-weight: 900;
    font-display: swap;
}
/* stylelint-enable */
